import React, { useState, useEffect, useCallback } from "react";
import api from "../../services/api";
import { Avatar } from "@material-ui/core";

const TicketInfo = ({ contact, ticket, onClick }) => {
    const { user, whatsappId } = ticket;
    const [userName, setUserName] = useState("");
    const [contactName, setContactName] = useState("");
    const [setor, setSetor] = useState(null);
    const [conexao, setConexao] = useState("");

    const fetchSetor = useCallback(async (userId) => {
        try {
            const { data } = await api.get(`/users/${userId}`);
            setSetor(data?.queues || []);
        } catch (e) {
            console.error("Erro ao buscar setor:", e);
        }
    }, []);

    const fetchConexao = useCallback(async (whatsappId) => {
        try {
            const { data } = await api.get(`/whatsapp/${whatsappId}`);
            setConexao(data?.name || "Não definida");
        } catch (e) {
            console.error("Erro ao buscar conexão de WhatsApp:", e);
        }
    }, []);

    const updateContactName = useCallback((name) => {
        if (document.body.offsetWidth < 600 && name.length > 10) {
            setContactName(`${name.substring(0, 10)}...`);
        } else {
            setContactName(name);
        }
    }, []);

    useEffect(() => {
        if (contact) {
            updateContactName(contact.name);
        }

        if (user) {
            setUserName(user.name);
            fetchSetor(user.id);
        }

        if (whatsappId) {
            fetchConexao(whatsappId);
        }
    }, [contact, user, whatsappId, fetchSetor, fetchConexao, updateContactName]);

    const SubheaderWithImage = () => (
        <div>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <img
                    src={require("../../assets/userIcon.svg")}
                    alt="User"
                    style={{ width: "18px", height: "18px" }}
                />
                <span style={{ fontSize: "12px" }}>{userName}</span>
            </div>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginTop: "8px",
                    flexWrap: "wrap",
                }}
            >
                {setor && setor.length > 0 ? (
                    setor.map((values, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "2px 4px",
                                fontSize: "12px",
                                border: `1px solid ${values.color || "#B3B3B3"}`,
                                borderRadius: "10px",
                                backgroundColor: values.color || "#f8f8f8",
                                color: "#fff",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                gap: "4px",
                            }}
                        >
                            {values.name}
                        </div>
                    ))
                ) : (
                    <div
                        style={{
                            padding: "2px 4px",
                            fontSize: "12px",
                            border: "1px solid #ccc",
                            borderRadius: "10px",
                            backgroundColor: "#f8f8f8",
                            color: "#333",
                        }}
                    >
                        Sem setor
                    </div>
                )}

                {conexao && (
                    <div
                        style={{
                            padding: "2px 4px",
                            fontSize: "12px",
                            border: "1px solid #ccc",
                            borderRadius: "10px",
                            backgroundColor: "#f8f8f8",
                            color: "#333",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            marginRight: "0",
                        }}
                    >
                        {conexao}
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div
            onClick={onClick}
            style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                padding: "8px 16px",
                cursor: "pointer",
            }}
        >
            <Avatar
                src={contact?.profilePicUrl}
                alt="contact_image"
                style={{ width: "48px", height: "48px" }}
            />

            <div style={{ flex: 1 }}>
                <div style={{ fontWeight: "bold", fontSize: "16px", lineHeight: "1.2" }}>
                    {contactName}
                </div>
                <div style={{ fontSize: "14px", color: "#555", marginTop: "4px" }}>
                    {user && <SubheaderWithImage />}
                </div>
            </div>
        </div>
    );
};

export default TicketInfo;
