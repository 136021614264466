import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";

import { Edit as EditIcon, Delete as Delete } from "@material-ui/icons";

import { toast } from "react-toastify";
import useCompanies from "../../hooks/useCompanies";
import usePlans from "../../hooks/usePlans";
import ModalUsers from "../ModalUsers";
import api from "../../services/api";
import { head, isArray, has } from "lodash";
import { useDate } from "../../hooks/useDate";
import { debounce } from 'lodash';
import { i18n } from "../../translate/i18n";


import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    width: "100%",
    flex: 1,
    padding: theme.spacing(1),
    borderRadius: '16px',
    //antes da tab
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff",
  },
  fullWidth: {
    width: "100%",
  },
  formField: {
    marginBottom: theme.spacing(1),
  },
  buttonTab: {
    backgroundColor: "#6EB131",
    // margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#fff",
    borderRadius: "60px",
  },
  dialogActions: {
    justifyContent: "space-between",
  },


  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    ...theme.scrollbarStyles,
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : theme.palette.options,
    borderRadius: '16px',
  },
  textfield: {
    width: "100%",
  },
  textRight: {
    textAlign: "right",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    padding: theme.spacing(0),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

// tabela
export function CompaniesManagerGrid(props) {
  const { records, onSelect, handleOpenShowConfirmDialog, handleOpenModal } = props;
  const classes = useStyles();
  const { dateToClient } = useDate();

  const renderStatus = (row) => {
    return row.status === false ? i18n.t("companiesManager.status.inactive") : i18n.t("companiesManager.status.active");
    // return row.status === false ? "Não" : "Sim";
  };

  const renderPlan = (row) => {
    return row.plan !== null ? row.plan.name : "-";
  };

  const renderCampaignsStatus = (row) => {
    if (
      has(row, "settings") &&
      isArray(row.settings) &&
      row.settings.length > 0
    ) {
      const setting = row.settings.find((s) => s.key === "campaignsEnabled");
      if (setting) {
        // return setting.value === "true" ? "Habilitadas" : "Desabilitadas";
        return setting.value === "true" ? i18n.t("companiesManager.campaignsStatus.enabled") : i18n.t("companiesManager.campaignsStatus.disabled");
      }
    }
    // return "Desabilitadas";
    return i18n.t("companiesManager.campaignsStatus.disabled");
  };

  const rowStyle = (record) => {
    if (moment(record.dueDate).isValid()) {
      const now = moment();
      const dueDate = moment(record.dueDate);
      const diff = dueDate.diff(now, "days");
      if (diff === 5) {
        return { backgroundColor: "#fffead" };
      }
      if (diff >= -3 && diff <= 4) {
        return { backgroundColor: "#f7cc8f" };
      }
      if (diff === -4) {
        return { backgroundColor: "#fa8c8c" };
      }
    }
    return {};
  };

  return (
    <Paper className={classes.tableContainer}>
      <Table
        className={classes.fullWidth}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {/* <TableCell align="left">Nome</TableCell>
            <TableCell align="left">E-mail</TableCell>
            <TableCell align="left">Telefone</TableCell>
            <TableCell align="left">Plano</TableCell>
            <TableCell align="left">Campanhas</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Criada Em</TableCell>
            <TableCell align="left">Vencimento</TableCell> */}
            <TableCell align="left">{i18n.t("companiesManager.table.name")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.email")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.phone")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.plan")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.campaigns")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.status")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.createdAt")}</TableCell>
            <TableCell align="left">{i18n.t("companiesManager.table.dueDate")}</TableCell>
            <TableCell align="center" style={{ width: "1%" }}>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row, key) => (
            <TableRow style={rowStyle(row)} key={key}>
              <TableCell align="left">{row.name || "-"}</TableCell>
              <TableCell align="left">{row.email || "-"}</TableCell>
              <TableCell align="left">{row.phone || "-"}</TableCell>
              <TableCell align="left">{renderPlan(row)}</TableCell>
              <TableCell align="left">{renderCampaignsStatus(row)}</TableCell>
              <TableCell align="left">{renderStatus(row)}</TableCell>
              <TableCell align="left">{dateToClient(row.createdAt)}</TableCell>
              <TableCell align="left">
                {dateToClient(row.dueDate)}
                <br />
                <span>{row.recurrence}</span>
              </TableCell>
              <TableCell align="center" style={{ width: "1%" }}>
                <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
                  <IconButton
                    // onClick={() => { }}
                    onClick={() => handleOpenShowConfirmDialog(row.id)}
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                  {/* <IconButton onClick={() => onSelect(row)} aria-label="edite"> */}
                  <IconButton onClick={() => handleOpenModal(row)} aria-label="edite">
                    <EditIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

// componente principal
export default function CompaniesManager() {
  const classes = useStyles();
  const { list, save, update, remove, listSearch } = useCompanies();
  const { list: listPlans } = usePlans();

  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [modalUser, setModalUser] = useState(false);
  const [firstUser, setFirstUser] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    loadPlans();
  }, []);

  // const debouncedSearch = debounce((value) => {
  //   fetchCompanies(value);
  // }, 300);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const companyList = await list();
      const planList = await listPlans();
      setRecords(companyList);
      setPlans(planList);
    } catch (e) {
      // toast.error("Não foi possível carregar a lista de registros");
      toast.error(i18n.t("companiesManager.toast.errorCompanies"));
    }
    setLoading(false);
  };

  const fetchCompanies = async (searchParam = "", pageNumber = 1) => {
    setLoading(true);
    try {
      const { companies } = await listSearch(searchParam, pageNumber); // Chama a API com o parâmetro de busca
      setRecords(companies);
    } catch (e) {
      //  toast.error("Erro ao buscar empresas");
      toast.error(i18n.t("companiesManager.toast.errorLoadCompanies"));

    }
    setLoading(false);
  };

  //  Atualiza as empresas sempre que searchTerm mudar
  useEffect(() => {
    // Debounce para evitar múltiplas chamadas rápidas
    const debouncedFetch = debounce(() => {
      fetchCompanies(searchTerm);
    }, 50);

    debouncedFetch(); // Chama a função debounced
    return () => {
      debouncedFetch.cancel(); // Cancela o debounce quando o componente desmontar ou searchTerm mudar
    };
  }, [searchTerm]);

  const handleOpenModal = (company = null) => {
    // Se for passado uma empresa, significa que é uma edição
    if (company) {
      const formattedDueDate = company.dueDate ? moment(company.dueDate).format('YYYY-MM-DD') : "";
      setRecord({
        ...company,
        dueDate: formattedDueDate, // Atualiza o campo com o valor formatado
      });
    } else {
      // Se não, abrimos o modal para uma nova empresa
      setRecord({
        name: "",
        email: "",
        phone: "",
        planId: "",
        status: true,
        campaignsEnabled: false,
        dueDate: "",
      });
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenShowConfirmDialog = (id) => {
    setSelectedId(id);
    setShowConfirmDialog(true);
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      let savedCompany;
      if (data.id) {
        savedCompany = await update(data);
      } else {
        savedCompany = await save(data);
      }
      await loadPlans();
      setRecord(savedCompany);
      handleCloseModal();
      // toast.success("Operação realizada com sucesso!");
      toast.success(i18n.t("companiesManager.toast.successUpdate"));
    } catch (e) {
      // toast.error("Erro ao salvar os dados.");
      toast.error(i18n.t("companiesManager.toast.errorUpdate"));
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      // await remove(record.id);
      await remove(selectedId);
      await loadPlans();
      handleCloseModal();
      // toast.success("Empresa excluída com sucesso!");
      toast.success(i18n.t("companiesManager.toast.successDelete"));
    } catch (e) {
      // toast.error("Erro ao excluir a empresa.");
      toast.error(i18n.t("companiesManager.toast.errorDelete"));
    }
    setLoading(false);
  };

  const handleOpenModalUsers = async () => {
    try {
      if (!record.id) {
        // toast.error("Empresa ainda não foi criada. Salve a empresa primeiro.");
        toast.error(i18n.t("companiesManager.toast.errorNotCompanie"));
        return;
      }

      const { data } = await api.get("/users/list", {
        params: {
          companyId: record.id, // Usa o ID da empresa recém-criada
        },
      });

      if (isArray(data) && data.length) {
        setFirstUser(head(data)); // Define o primeiro usuário, se existir
      } else {
        setFirstUser({}); // Se não houver usuários, inicializa como vazio
      }

      setModalUser(true); // Abre o modal de usuários
    } catch (e) {
      toast.error(i18n.t("companiesManager.toast.errorUsers"));
      // toast.error("Erro ao carregar usuários da empresa.");
    }
  };

  const handleCloseModalUsers = () => {
    setFirstUser({});
    setModalUser(false);
  };

  // Função para lidar com a digitação no campo de busca e atualizar o estado searchTerm
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Atualiza o estado do searchTerm
  };

  return (
    <Paper className={classes.mainPaper} elevation={0}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className={classes.buttonContainer} style={{ display: 'flex', alignItems: 'center', gap: "8px" }} >

            <TextField
              variant="outlined"
              // placeholder="Buscar"
              placeholder={i18n.t("companiesManager.searchPlaceholder")}
              value={searchTerm}
              onChange={handleSearchChange}
              className={classes.formField}
              size="small"
              style={{ borderRadius: '16px' }}
            />
            <Button
              size="medium"
              className={classes.buttonTab}
              onClick={() => handleOpenModal()}
              style={{ paddingLeft: '24px', paddingRight: '24px' }}
            >
              {/* + Adicionar Empresa */}
              {i18n.t("companiesManager.addButton")}
            </Button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <CompaniesManagerGrid
            handleOpenShowConfirmDialog={handleOpenShowConfirmDialog}
            handleOpenModal={handleOpenModal}
            setShowConfirmDialog={setShowConfirmDialog}
            records={records}
            onSelect={setRecord}
          />
        </Grid>
      </Grid>

      {/* modal para criar usuário */}
      <ModalUsers
        userId={firstUser.id || null}
        companyId={record.id}
        open={modalUser}
        onClose={handleCloseModalUsers}
      />

      {/* modal para adicionar empresa */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm" // Diminuir a largura do modal
        style={{ minWidth: '400px' }} // Controla o tamanho mínimo do modal
      >
        {/* <DialogTitle>{record.id ? "Editar Empresa" : "Adicionar Empresa"}</DialogTitle> */}
        <DialogTitle>{record.id ? i18n.t("companiesManager.form.titleEdit") : i18n.t("companiesManager.form.titleAdd")}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={record}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={2} style={{ marginTop: '16px', marginBottom: '16px' }}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      // label="Nome"
                      label={i18n.t("companiesManager.form.name")}
                      name="name"
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <Field
                      as={TextField}
                      // label="E-mail"
                      label={i18n.t("companiesManager.form.email")}
                      name="email"
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      // label="Telefone"
                      label={i18n.t("companiesManager.form.phone")}
                      name="phone"
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                      style={{ marginTop: '16px', marginBottom: '16px' }} // Aumentar espaçamento superior e inferior
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.formField} >
                      {/* <InputLabel>Plano</InputLabel> */}
                      <InputLabel>{i18n.t("companiesManager.form.plan")}</InputLabel>
                      <Field as={Select} name="planId" style={{ marginTop: '16px', marginBottom: '16px' }}>
                        {plans.map((plan) => (
                          <MenuItem key={plan.id} value={plan.id}>
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.formField} >
                      {/* <InputLabel>Status</InputLabel> */}
                      <InputLabel>{i18n.t("companiesManager.form.status")}</InputLabel>
                      <Field as={Select} name="status" style={{ marginTop: '14px', marginBottom: '16px' }}>
                        {/* <MenuItem value={true}>Ativo</MenuItem>
                        <MenuItem value={false}>Inativo</MenuItem> */}
                        <MenuItem value={true}>{i18n.t("companiesManager.status.active")}</MenuItem>
                        <MenuItem value={false}>{i18n.t("companiesManager.status.inactive")}</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.formField} >
                      {/* <InputLabel>Campanhas</InputLabel> */}
                      <InputLabel>{i18n.t("companiesManager.form.campaigns")}</InputLabel>
                      <Field as={Select} name="campaignsEnabled" style={{ marginTop: '14px', marginBottom: '16px' }}>
                        {/* <MenuItem value={true}>Habilitado</MenuItem>
                        <MenuItem value={false}>Desabilitado</MenuItem> */}
                        <MenuItem value={true}>{i18n.t("companiesManager.campaignsStatus.enabled")}</MenuItem>
                        <MenuItem value={false}>{i18n.t("companiesManager.campaignsStatus.disabled")}</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      // label="Data de Vencimento"
                      label={i18n.t("companiesManager.form.dueDate")}
                      name="dueDate"
                      type="date"
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                      InputLabelProps={{ shrink: true }}
                      style={{ marginTop: '16px', marginBottom: '16px' }} // Aumentar espaçamento superior e inferior
                    />
                  </Grid>
                </Grid>

                <DialogActions className={classes.dialogActions} style={{ justifyContent: record.id ? "space-between" : "flex-end" }}>
                  {record.id && (
                    <ButtonWithSpinner
                      className={classes.buttonUser}
                      onClick={() => handleOpenModalUsers()}
                      style={{ size: "medium", padding: "6px 14px", backgroundColor: '#6EB131', color: '#fff' }}
                      disabled={!record.id}
                    >
                      {/* Usuário */}
                      {i18n.t("companiesManager.form.user")}
                    </ButtonWithSpinner>
                  )}
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      onClick={handleCloseModal}
                      variant='outlined'
                    // color='secondary'
                    // style={{ size: "medium", padding: "8px 16px", backgroundColor: '#111433', color: '#fff' }}
                    >
                      {/* Cancelar */}
                      {i18n.t("companiesManager.form.cancel")}
                    </Button>
                    <ButtonWithSpinner
                      type="submit"
                      style={{ size: "medium", padding: "6px 14px", backgroundColor: '#6EB131', color: '#fff' }}
                      loading={loading}
                    >
                      {/* Salvar Alterações */}
                      {i18n.t("companiesManager.form.save")}
                    </ButtonWithSpinner>
                  </div>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>


      <ConfirmationModal
        title="Exclusão de Registro"
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleDelete}
      >
        {/* Deseja realmente excluir esse registro? */}
        {i18n.t("companiesManager.confirmDeletion.message")}
      </ConfirmationModal>
    </Paper>
  );
}

